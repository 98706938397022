import React, { FC } from 'react'

import { Box, Flex, Text } from 'src/components'

const PrivacyPolicy: FC = () => (
  <Box
    width="100%"
    maxWidth="848px"
    border="solid"
    borderRadius="l"
    mt={['30px', '60px']}
    height="fit-content"
    mx="16px"
    mb="61px"
    py={['32px', '64px']}
    px={['40px', '80px']}
    boxShadow="l"
    bg="background"
  >
    <Flex flexDirection="column">
      <Box mb="24px">
        <Text fontSize={['xlMob', 'xl']} fontWeight="bold">
          Privacy Policy
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        The website accessible through www.hitchhero.com (the “Website”) is
        owned, operated and distributed by HitchHero (“HitchHero” and also
        referred to in this Privacy Policy as “we” and through similar words
        such as “us,” “our,” etc.). BY ACCESSING ANY PART OF THE WEBSITE, YOU
        ARE AGREEING TO THE TERMS AND CONDITIONS DESCRIBED BELOW (THE “PRIVACY
        POLICY”) AND THE TERMS AND CONDITIONS OF OUR TERMS OF USE (THE “TERMS OF
        USE”). IF YOU DO NOT AGREE TO ANY OF THESE TERMS, YOU SHOULD NOT USE THE
        WEBSITE. This Privacy Policy applies to all users, including both users
        who are simply viewing the Website and users who have subscribed for
        services or contributed content. We may, at our sole discretion, modify
        this Privacy Policy at any time. By accessing the Website at any time
        after such modifications, you are agreeing to such modifications. This
        Privacy Policy was last modified as of July 17, 2019.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          What Personally Identifiable Information Do We Collect?
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        We collect the following types of personally identifiable information:
        <br />
        <br />
        <b>User Provided Information:</b> You may affirmatively provide us with
        certain personally identifiable information (such as your name and email
        address) when participating in various activities on the Website.
        <br />
        <br />
        <b>Information Collected by “Cookies”:</b> When you visit the Website,
        we may send one or more “cookies” to your computer. A “cookie” is a
        small line of text that is stored with your Web browser, which we use to
        help provide better service to you. Your browser has options to accept,
        reject or provide you with notice when a cookie is sent. We may use
        cookies for many purposes, including (without limitation) to save your
        password so you don’t have to re-enter it each time you visit the
        Website, and to deliver content (which may include third party
        advertisements) specific to your interests.
        <br />
        <br />
        <b>Log File Information:</b> Our servers automatically record certain
        information that your web browser sends whenever you visit the Website.
        These server logs may include information such as your web request,
        Internet Protocol (“IP”) address, browser type, browser language,
        referring / exit pages and URLs, platform type, number of clicks, domain
        names, landing pages, pages viewed and the order of those pages, the
        amount of time spent on particular pages, the date and time of your
        request, and one or more cookies that may uniquely identify your
        browser.
        <br />
        <br />
        <b>Information Collected by “Clear GIFs”:</b> We may employ “clear GIFs”
        (a.k.a. Web Beacons), which are used to track the online usage patterns
        anonymously (i.e., in a non-personally-identifiable manner). In
        addition, we may also use clear GIFs in HTML-based emails sent to users
        to track which emails are opened by recipients.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold" lineHeight={1}>
          How Do We Use Personally Identifiable Information?
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        Any personal information that you voluntarily disclose online (on
        discussion boards, in messages and chat areas, within your public
        profile page, etc.) becomes publicly available and can be collected and
        used by others. If you have not opted out from receiving emails from us,
        we may use your email address or other personally identifiable
        information to send commercial or marketing messages on behalf of
        ourselves, HitchHero. We also may use your email address for
        non-marketing or administrative purposes (such as notifying you of major
        Website changes or for customer service purposes). You cannot opt out of
        receiving emails for non-marketing or administrative purposes. We use
        aggregated information (such as anonymous user usage data, cookies, IP
        addresses, browser type, clickstream data, etc.) to improve the quality
        and design of the Website and to create new features, promotions,
        functionality, and services by storing, tracking, and analyzing user
        preferences and trends. In addition, we may use “cookies,” clear gifs
        and log file information to deliver content (which may include third
        party advertisements) specific to your interests, and remember
        information so that you will not have to re-enter it each time you visit
        the Website.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Will We Share Personally Identifiable Information with Others?
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        We will not share your personally identifiable information with other,
        third-party companies for their commercial or marketing use without your
        consent or except as part of a specific program or feature for which you
        have the ability to opt-in or opt-out. We may provide personally
        identifiable information to employees, consultants, affiliates or other
        businesses or persons for the purpose of processing such information on
        our behalf. In such circumstances, we require that these parties agree
        to protect the confidentiality of such information and to comply in all
        respects with this Privacy Policy. We may share
        non-personally-identifiable information (such as anonymous User usage
        data, referring / exit pages and URLs, platform types, number of clicks,
        etc.) with interested third-parties to assist them in understanding the
        usage patterns for certain content, services, advertisements,
        promotions, and/or functionality on the Website. In addition, we may
        release personally identifiable information: (i) to the extent we have a
        good-faith belief that such action is necessary to comply with any
        applicable law, enforce any provision of the Terms of Use, protect
        ourselves against any liability, defend ourselves against any claims,
        protect the rights, property and personal safety of any user or protect
        the public welfare; or (ii) to respond to a court order, subpoena, or
        search warrant.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Third-party Advertisers
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        We may allow other companies, called third-party ad servers or ad
        networks, to serve advertisements within the Website. These third-party
        ad servers or ad networks use technology to send, directly to your
        browser, the advertisements and links that appear on the Website. They
        automatically receive your IP address when this happens. They may also
        use other technologies (such as cookies, JavaScript, or Web Beacons) to
        measure the effectiveness of their advertisements and to personalize the
        advertising content you see. You should consult the respective privacy
        policies of these third-party ad servers or ad networks for more
        information on their practices and for instructions on how to opt-out of
        certain practices. This Privacy Policy does not apply to them, and we
        cannot control their activities.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Is My Information Secure?
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        We have implemented security measures to protect personally identifiable
        information collected on the Website against loss, misuse or alteration.
        In addition, your account information is protected by a password for
        your privacy and security. It is important that you protect against
        unauthorized access to your password and to your computer by logging off
        once you have finished using a shared computer. Moreover, despite the
        precautions described above, we cannot and do not guarantee that the
        individual user information you have transmitted will not be intercepted
        by others and/or decrypted or accidentally disclosed, and we accept no
        liability for any unintentional disclosure.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          In the Event of Merger, Sale, or Bankruptcy
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        In the event that we are acquired by or merged with a third-party
        entity, we reserve the right to transfer or assign the information we
        have collected from users as part of such merger, acquisition, sale, or
        other change of control. In the unlikely event of our bankruptcy,
        insolvency, reorganization, receivership, or assignment for the benefit
        of creditors, or the application of laws or equitable principles
        affecting creditors’ rights generally, we may not be able to control how
        your personal information is treated, transferred, or used.   
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Children’s Privacy
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        You must be at least 13 years old to use the Website.
      </Text>

      <Box my="24px">
        <Text fontSize={['l', 'xlMob']} fontWeight="bold">
          Special Note to International Users
        </Text>
      </Box>

      <Text fontSize={['m', 'lMob']}>
        The Website is hosted in the United States and is intended for and
        directed to users in the United States. If you are accessing the Website
        from the European Union, Asia, or any other region with laws or
        regulations governing personal data collection, use, and disclosure,
        that differ from United States laws, please be advised that through your
        continued use of the Website, which is governed by U.S. law, this
        Privacy Policy, and our Terms of Use, you are transferring your personal
        information to the United States and you consent to that transfer.
      </Text>
    </Flex>
  </Box>
)

export default PrivacyPolicy
