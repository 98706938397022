import React, { FC } from 'react'

import { Layout, Seo } from 'src/components'
import { PrivacyPolicy } from 'src/components/Privacy'

const PrivacyPage: FC = () => (
  <Layout>
    <Seo title="Privacy Policy" />

    <PrivacyPolicy />
  </Layout>
)

export default PrivacyPage
